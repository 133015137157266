import { useEffect, useRef, useState } from "react";
import { theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { resetError } from "../features/patient/patientSlice";

export default function useAddBlackList(isOpened, onCancel, patient) {
  const { useToken } = theme;
  const { token } = useToken();
  const { loading, error, success } = useSelector((state) => state?.patient);
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    if (patient) {
      setFormData([
        {
          name: "blacklist_reason",
          value: patient?.blacklist_reason ? patient?.blacklist_reason : "",
        },
      ]);
    }
  }, [isOpened, patient]);

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          formRef?.current?.setFields([
            {
              name: key, // Field name you want to set the error for
              errors: [error[key][0]], // Assuming your error has a 'message' field
            },
          ]);
        }
      }
    }
  }, [error, loading, isOpened]);

  useEffect(() => {
    if (isOpened) {
      if (success) {
        onCancel();
        dispatch(resetError());
        formRef?.current?.resetFields();
        onCancel();
        setFormData([]);
      }
    }
  }, [success, dispatch, isOpened, onCancel]);

  return { token, formRef, formData, error, loading, dispatch, resetError };
}

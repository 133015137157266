import React from "react";
import "./AddBlackList.scss";
import { Modal, Form, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import useAddBlackList from "../../hooks/useAddBlackList";

export default function AddBlackList({
  isOpened,
  title,
  onCancel,
  onOk,
  patient,
}) {
  const { token, formRef, formData, error, loading, dispatch, resetError } =
    useAddBlackList(isOpened, onCancel, patient);

  return (
    <Modal
      className="blackListModal-cont"
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}
      destroyOnClose={true}
      open={isOpened}
      title={title}
      centered={true}
      okText="Save"
      closeIcon={false}
      cancelButtonProps={{
        className: "blackListModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        className: "blackListModal-cont__btn",
        htmlType: "submit",
        type: "primary",
        key: "submit",
        loading: loading && isOpened,
        disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}
      onCancel={() => {
        onCancel();
        formRef?.current?.resetFields();
        if (error) {
          dispatch(resetError());
        }
      }}
      onOk={() => {
        formRef?.current?.submit();
      }}>
      <Form
        className="blackListModal-cont__form"
        ref={formRef}
        layout="vertical"
        onFinish={(values) => {
          onOk(values);
        }}
        onFinishFailed={(error) => {}}
        fields={formData}>
        <div className="blackListModal-cont__form__formItemWrapper">
          <Form.Item
            className="blackListModal-cont__form__formItemWrapper__formItem"
            name="blacklist_reason"
            rules={[
              {
                required: true,
                message: "Please eneter reason",
              },
            ]}
            label={
              <Typography.Text className="blackListModal-cont__form__formItemWrapper__formItem__text">
                Reason
              </Typography.Text>
            }
            colon={false}>
            <TextArea
              placeholder="Reason"
              onChange={() => {
                if (error && error["blacklist_reason"]) {
                  dispatch(resetError("blacklist_reason"));
                }
              }}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
